import axios from "axios";
import advertMapper from "../../../libs/advertMapper";
import { set, get } from "../../../libs/idb.js";
const state = {
  adverts: [],
};

const getters = {
  advertById: (state, _, rootState) => (id) => {
    let loginId = rootState.auth.loginId;
    return state.adverts.find((e) => e.id === id && e.loginId === loginId);
  },
  advertByRemoteId: (state, _, rootState) => (remoteId) => {
    let loginId = rootState.auth.loginId;
    return state.adverts.find((e) => e.remoteId === remoteId && e.loginId === loginId);
  },
  tmpAdverts: (state, _, rootState) => {
    let loginId = rootState.auth.loginId;
    return state.adverts.filter((add) => !add.activated && add.loginId === loginId);
  },
  sendAdverts: (state, _, rootState) => {
    let loginId = rootState.auth.loginId;
    return state.adverts.filter((add) => add.activated && add.loginId === loginId);
  },
  tokenIsValid: (state, _, rootState) => {
    if (rootState.auth.token) {
      let tokenExpiration = rootState.auth.token.expiration;
      const now = new Date().getTime();
      return tokenExpiration > now;
    } else if (!rootState.token) {
      return false;
    }
  },
  generateTmpId: (state) => {
    let tmpIds = [0];
    state.adverts.forEach(function (e) {
      if (e.id instanceof String || typeof e.id === "string") {
        let tmpid = parseInt(e.id.replace("tmp", ""));
        tmpIds.push(tmpid);
      }
    });
    let number = Math.max.apply(null, tmpIds) + 1;
    return "tmp" + number;
  },
  getImages: (state) => (id) => {
    return get(id);
  },
  countAdverts: (state) => (id) => {
    if (!state.adverts.length) {
      return 0;
    } else {
      const adverts = state.adverts.filter((e) => e.loginId === id);
      return adverts.length;
    }
  },
  filterTakenAdverts: (state) => (id) => {
    return state.adverts.filter((e) => e.remoteId === id);
  },
  getSavedTakers: (state) => {
    //console.log("called");
    let filteredAdverts = [];
    filteredAdverts.push(
      state.adverts.filter((advert) => {
        if (advert.zajemci) {
          return advert;
        }
      })
    );
    console.log(filteredAdverts);
    return filteredAdverts;
  },
};

const mutations = {
  setStep(state, advert) {
    let adIndex = state.adverts.findIndex((e) => e.id === advert.id);
    state.adverts[adIndex].steps[advert.stepCount] = advert.step;
  },
  async setImages(state, images) {
    let imageElementId = 1;
    const imagesToSave = {};
    images.images.forEach((item, index) => {
      let position = index + 1;
      let img = {
        base: item.base,
        modified: true,
        path: item.path,
        dataid: imageElementId,
      };
      imagesToSave[position] = img;
      imageElementId++;
    });

    for (let counter = 1; counter <= 10; counter++) {
      let img = imagesToSave[counter];
      // console.log(img)
      if (typeof img == "undefined") {
        imagesToSave[counter] = { base: null, modified: true, path: null };
      }
    }
    await set(images.id, imagesToSave);
  },
  setAddress(state, advert) {
    let adIndex = state.adverts.findIndex((e) => e.id === advert.id);
    state.adverts[adIndex].address = advert.address;
  },
  createAdvert(state, add) {
    state.adverts.push(add);
  },
  removeAdvertById(state, id) {
    for (var i = 0; i < state.adverts.length; i++) {
      if (state.adverts[i].id === id) {
        state.adverts.splice(i, 1);
      }
    }
  },
  setRemoteId(state, advert) {
    let adIndex = state.adverts.findIndex((e) => e.id === advert.id);
    state.adverts[adIndex].remoteId = advert.remoteId;
  },
  async setPathToImage(state, imgRequest) {
    let adIndex = state.adverts.findIndex((e) => e.remoteId === imgRequest.id);
    let tmpId = state.adverts[adIndex].id;
    let images = await get(tmpId);
    images[imgRequest.poradi].path = imgRequest.path;
    await set(tmpId, images);
  },
  setActivated(state, addId) {
    let adIndex = state.adverts.findIndex((e) => e.id === addId);
    state.adverts[adIndex].activated = true;
  },

  deleteRemovedAdverts(state, data) {
    const idsToRemove = [];
    data.localeAdverts.forEach((add) => {
      if (!data.remoteIds.includes(add.remoteId)) {
        idsToRemove.push(add.remoteId);
      }
    });

    const filtered = state.adverts.filter(function (value, index, array) {
      return !idsToRemove.includes(value.remoteId);
    });

    state.adverts = filtered;
  },
  setTakers(state, advert) {
    let adIndex = state.adverts.findIndex((e) => e.remoteId === advert.id);
    state.adverts[adIndex].zajemci = advert.zajemci;
  },
};

const actions = {
  async createNewAdvert({ commit, getters }) {
    let tmpId = getters.generateTmpId;

    let add = {
      id: tmpId,
      remoteId: null,
      loginId: this.state.auth.loginId,
      steps: {
        1: {},
        2: {},
        3: {},
        4: {},
      },
      images: {
        1: { base: null, modified: false, path: null },
        2: { base: null, modified: false, path: null },
        3: { base: null, modified: false, path: null },
        4: { base: null, modified: false, path: null },
        5: { base: null, modified: false, path: null },
        6: { base: null, modified: false, path: null },
        7: { base: null, modified: false, path: null },
        8: { base: null, modified: false, path: null },
        9: { base: null, modified: false, path: null },
        10: { base: null, modified: false, path: null },
      },
      address: {
        street: null,
        cp: null,
        co: null,
        ce: null,
        ob: null,
        cbo: null,
        psc: null,
        distName: null,
        okres: null,
        id_mesto: null,
        id_katastr: null,
        latitude: null,
        longitude: null,
      },
    };
    commit("createAdvert", add);
    return add;
  },

  async updateStepOne({ commit }, advert) {
    advert.stepCount = 1;
    commit("setStep", advert);
  },

  async updateStepTwo({ commit }, advert) {
    advert.stepCount = 2;
    commit("setStep", advert);
  },
  async updateStepThree({ commit }, advert) {
    let add = {
      id: advert.id,
      step: advert.step,
      stepCount: 3,
    };
    let images = {
      id: advert.id,
      images: advert.images,
    };
    commit("setStep", add);
    commit("setImages", images);
  },
  async updateStepFour({ commit }, advert) {
    advert.stepCount = 4;
    commit("setStep", advert);
  },
  async updateAddress({ commit }, advert) {
    commit("setAddress", advert);
  },

  async removeAdvert({ commit }, id) {
    commit("removeAdvertById", id);
  },
  async setRemoteId({ commit }, advert) {
    commit("setRemoteId", advert);
  },

  async setPathToImage({ commit }, imgRequest) {
    commit("setPathToImage", imgRequest);
  },
  async updateAdverts({ dispatch, getters, commit, rootState }) {
    let token = getters.tokenIsValid ? rootState.auth.token.token : await dispatch("getToken");
    try {
      const response = await axios.post("zakazky/", { token: token });
      // console.log(response)
      if (response.data.status === "OK") {
        let remoteAdds = response.data.info.zak;

        return await Promise.all(
          remoteAdds.map(async (remoteAdd) => {
            if (getters.advertByRemoteId(remoteAdd.id)) {
              const adverts = await dispatch("updateAdvertFromApi", remoteAdd.id);
              // console.log(adverts)
              return adverts;
            }
            if (!getters.advertByRemoteId(remoteAdd.id)) {
              let zakDetail = await axios.post("zakazka/" + remoteAdd.id + "/", { token: token });
              // console.log(zakDetail)
              let zak = zakDetail.data.info.zak;
              let newAdd = await advertMapper.apiToVuex(zak);
              newAdd.id = getters.generateTmpId;
              commit("createAdvert", newAdd);
              commit("setImages", newAdd);
              return newAdd;
            }
          })
        );
      }
    } catch (err) {
      return err;
    }
  },

  async updateAdvertFromApi({ dispatch, commit, getters, rootState }, remoteId) {
    let token = getters.tokenIsValid ? rootState.auth.token.token : await dispatch("getToken");
    // console.log(token);
    try {
      const response = await axios.post("zakazka/" + remoteId + "/", { token: token });
      if (response.data.status === "OK") {
        // console.log(response)
        let zak = response.data.info.zak;
        let newAdd = await advertMapper.apiToVuex(zak);
        let oldAdd = getters.advertByRemoteId(remoteId);
        newAdd.id = oldAdd.id;
        commit("setAddress", newAdd);
        commit("setImages", newAdd);
        commit("setStep", { id: newAdd.id, step: newAdd.steps[1], stepCount: 1 });
        commit("setStep", { id: newAdd.id, step: newAdd.steps[2], stepCount: 2 });
        commit("setStep", { id: newAdd.id, step: newAdd.steps[3], stepCount: 3 });
        commit("setStep", { id: newAdd.id, step: newAdd.steps[4], stepCount: 4 });
        if (newAdd.activated) {
          commit("setActivated", newAdd.id);
        }
        return newAdd;
      }
    } catch (err) {
      return err;
    }
  },

  async setActivated({ commit }, addId) {
    commit("setActivated", addId);
  },

  async deleteRemovedAdverts({ dispatch, getters, commit, rootState }) {
    let token = getters.tokenIsValid ? rootState.auth.token.token : await dispatch("getToken");
    await axios.post("zakazky/", { token: token }).then(async (response) => {
      // console.log(response)
      if (response.data.status === "OK") {
        let remoteAddIds = [];
        if (response.data.info.zak) {
          let remoteAdds = response.data.info.zak;
          remoteAdds.forEach((el) => {
            remoteAddIds.push(el.id);
          });

          commit("deleteRemovedAdverts", { remoteIds: remoteAddIds, localeAdverts: getters.sendAdverts });
        }
      }
    });
  },
  async getTakers({ commit, getters, dispatch, rootState }) {
    try {
      let token = getters.tokenIsValid ? rootState.auth.token.token : await dispatch("getToken");
      const response = await axios.post("zajemci/", { id: getters.getId, token: token });
      let zajemci;
      if (response.data.status === "OK") {
        zajemci = response.data.info.zakazky;
        // console.log(zajemci)
        // save to local storage
        zajemci.map((advert) => {
          if (advert.zajemci) {
            commit("setTakers", advert);
          }
        });

        // display on page
        const filteredTakers = zajemci.filter((advert) => advert.zajemci);
        const takenAdverts = filteredTakers.map((advert) => getters.filterTakenAdverts(advert.id));
        // console.log(filteredTakers)
        // console.log(takenAdverts)
        return {
          //filteredTakers,
          takenAdverts,
        };
      }
    } catch (err) {
      console.log(err);
      // const response = navigator.onLine
      //   ? "Něco se nepovedlo. Zkuste prosím inzeráty aktualizovat později."
      //   : "Nejste připojeni k internetu";
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
