import './smap-external.js';
import EventBus from "../event-bus";
Loader.load(null, {suggest: true});

function initSuggester() {

  class MyItem extends SMap.SuggestItem {
    /**
     Upravime vytvareni polozky naseptavace - pridame info o cvrti pokude existuje
     */
    _create() {
      let node = document.createElement("li");
      node.classList.add("item");

      let titlePart = document.createElement("span");
      titlePart.classList.add("text");

      let title = document.createElement("strong");
      title.innerHTML = this._data.title;

      titlePart.appendChild(title);

      // do prvniho radku naseptavace dame informaci o ctvrti; pokud neni, tak standardni vypis
      this._addRow(titlePart, this._data.origData.quarter || this._data.secondRow);
      this._addRow(titlePart, this._data.thirdRow);

      this._dom.node = node;

      node.appendChild(this._createIcon());
      node.appendChild(titlePart);
    }
  }

  class MyProvider extends SMap.SuggestProvider {
    /**
     Upravime ziskavani dat pro naseptavac - preposleme do suggest item vsechny puvodni data,
     kde se prave muze vyskytovat ctvrt.
     */
    _processData() {
      if (!this._responseData) return;

      let rawData = JSON.parse(this._responseData.data);

      if (rawData.result && Array.isArray(rawData.result)) {
        let resultsItems = rawData.result.map(item => {
          let poiTypeId = item.userData.poiTypeId || 0;
          let firstRow = (item.userData.suggestFirstRow || "").trim();
          let id = item.userData.id;

          if (item.userData.source == "coor" && typeof id === "number") {
            id = item.userData.longitude + "," + item.userData.latitude;
          }

          return {
            longitude: parseFloat(item.userData.longitude),
            latitude: parseFloat(item.userData.latitude),
            source: item.userData.source,
            id: id,
            title: firstRow,
            secondRow: (item.userData.suggestSecondRow || "").trim(),
            thirdRow: (item.userData.suggestThirdRow || "").trim(),
            phrase: firstRow || item.sentence,
            iconType: item.userData.iconType || "",
            iconUrl: this._getIconUrl(poiTypeId),
            poiTypeId: poiTypeId,
            origData: item.userData // pridame si informaci o vsech datech
          };
        });

        this._promise.fulfill(resultsItems);
      }

      this._promise = null;
      this._request = null;
    }
  };


  const inputEl = document.getElementById('place');
    if( !inputEl )
      return;

    const provider = new MyProvider({limit: 10});

    const suggest = new SMap.Suggest(inputEl,{
      factory:(data, pos) => new MyItem(data, pos),
      provider: provider,
    });

    // console.log(suggest);
    suggest.addListener("suggest", function (suggestData) {
      // console.log(suggestData.data["origData"]["source"]);
      if( suggestData.data["origData"]["source"] === "addr" || suggestData.data["origData"]["source"] === "stre")
      {
        const address = {
          street: suggestData.data["origData"]["street"]
        };

        if (suggestData.data["origData"]["houseNumber"]!=""){
          address.cp = suggestData.data["origData"]["houseNumber"];
        }
        if (suggestData.data["origData"]["streetNumber"]!=""){
          address.co = suggestData.data["origData"]["streetNumber"];
        }
        if (suggestData.data["origData"]["evidenceNumber"]!="") {
          address.ce = suggestData.data["origData"]["evidenceNumber"];
        }
        if (suggestData.data["origData"]["ward"]!="") {
          address.cbo = suggestData.data["origData"]["ward"];
        }
        if (suggestData.data["origData"]["municipality"]!="") {
          address.ob = suggestData.data["origData"]["municipality"];
        }
        if (suggestData.data["origData"]["district"]!="") {
          address.distName = suggestData.data["origData"]["district"];
        }
        if (suggestData.data["origData"]["zipCode"]!="") {
          address.psc = suggestData.data["origData"]["zipCode"];
        }
        address.latitude = suggestData.data.latitude;
        address.longitude = suggestData.data.longitude;
        EventBus.$emit('naseptavac', address);
      }


    }).addListener("close", function () {
    });
};

export default {
  initSuggester
}

window.addEventListener('load', initSuggester, false );
