import axios from 'axios';

const state = {
  credentials: null,
  token: null,
  loginId: null,
  details: {
    jmeno: null,
    prijmeni: null,
    kontakt: null,
    telefon: null,
    ulice: null,
    cp: null,
    obec: null,
    psc: null,
    verified: false,
  },
};

const getters = {
  isAuthenticated(state) {
    return state.credentials !== null;
  },
  getTokenExpiration(state){
    return state.token.expiration;
  },
  getToken(state){
    return state.token.token;
  },

  getId(state){
    return state.loginId;
  },

  getDetails(state){
    return state.details;
  },

  getEmail(state){
    return state.credentials.username;
  }
};

const actions = {
  async LogIn({commit, dispatch}, credentials) {
    return axios.post('/login/', {"user": credentials}).then(response => {
      
      if (response.data.status === 'OK') {
        commit("setCredentials", credentials);
        commit("setToken", response.data.info.token);
        commit("setLoginId", response.data.info.ID);
        dispatch("updateDetails");
      }

      return response;
    });
  },
  async updateDetails({commit, state}){
    return await axios.post('uzivatel/' + state.loginId + '/', {token: state.token.token}).then(response => {
      if (response.data.status == "OK")
      {
        let user = response.data.info.user;
        commit("setDetails", user);
      }

      return response;
    })
  },

  async Registation({commit, dispatch}, credentials) {
    return await axios.post('/registrace/', credentials,).then( response => {
      if (response.data.status === 'OK') {
        let cred = {
          password: response.data.info.password,
          username: credentials.user.email,
        }
        credentials.user.password = response.data.info.password;
        commit("setCredentials", cred);
        commit("setLoginId", response.data.info.ID);
        //dispatch('LogIn', state.credentials);
      }

      return response;
    });
  },

  async Recovery({commit, dispatch}, credentials) {
    return await axios.post('/obnova/', credentials,).then(response => {
      if (response.data.status === 'OK') {
        //commit("setCredentials", credentials);
        dispatch('LogOut', state.credentials);
      }

      return response;
    });
  },
  
  async getToken({dispatch, state}) {
    await dispatch('LogIn', state.credentials);
    return state.token.token;
  },

  async LogOut({commit}) {
    commit('LogOut');
  },

  async setDetails({commit}, data){
    return await axios.post( 'uzivatel/', data).then(response => {
      if( response.data.status === 'OK' ) {
        commit("setDetails", data.user );
      }

      return response;
    });
  },

  async reset({commit}){
    commit("reset");
  },

  async setVerified({commit}){
    commit("setVerified");
  }
}

const mutations = {

  setCredentials(state, credentials) {
    state.details.email = credentials.email;
    state.credentials = credentials;
  },

  setLoginId(state, loginId) {
    state.loginId = loginId;
  },

  setToken(state, token) {
    let today = new Date();
    state.token = {
      "token": token,
      'expiration': new Date(today.getTime() + 3 * 60000),
    };
    // console.log(state.token)
  },

  setDetails( state, details){
    if( typeof details.verified == 'undefined')
      details.verified = state.details.verified;

    state.details = details;
  },

  setVerified(state) {
    state.details.verified = true;
  },

  LogOut(state) {
    state.credentials = null;
    state.token = null;
    state.loginId = null;
    state.details = {
      email: null,
      jmeno: null,
      prijmeni: null,
      kontakt: null,
      telefon: null,
      ulice: null,
      cp: null,
      obec: null,
      psc: null,
      verified: false,
    };
  },
  deleteAccount(state,_,rootState){
    rootState.multistep.adverts=[];
    state.credentials = null;
    state.token = null;
    state.loginId = null;
    state.details = {
      email: null,
      jmeno: null,
      prijmeni: null,
      kontakt: null,
      telefon: null,
      ulice: null,
      cp: null,
      obec: null,
      psc: null,
      verified: false,
    };
  },

  reset(){
    let newState = {};
    this.replaceState(newState);
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}