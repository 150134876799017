import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import "../libs/smap/init"
import "../libs/smap/geocoder-reverse"

import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';

axios.defaults.headers.common['Authorization'] = 'Basic ' + btoa('2021pass2021');
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = 'https://www.realitni-samoobsluha.cz/API/v1/';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const app = createApp(App)
    .use(router)
    .use(VueAxios, axios)
    .use(store)
    .mount('#app');

export default {
  axios
}