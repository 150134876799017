<template>
  <section class="videoPanel" id="myVideo">
    <button class="video-close" type="button" data-uk-close @click="closeVideoPanel"></button>
    <video id="myVideoPlayer" src="../assets/video/navod.mp4" uk-video="autoplay: false" controls></video>
  </section>
</template>

<script>
  export default({
    emits:['closeVideo'],
    methods: {
      closeVideoPanel: function(event){
            if (event) {
              this.$emit('closeVideo',event);
            }
        },
    },

  });

</script>
<style lang="less">
  section.videoPanel{
    position: fixed;
    left: 0;
    right: 0;
    max-width: 42.8rem;
    width: 100%;
    background: rgba(0,0,0,0.9);
    height: 100vh;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    display: flex;
    margin: auto;
    bottom:-100vh;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    z-index: 1001;
    .video-close{
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: #2FAC66;
      z-index: 1002;
      svg{
        width: 4rem;
        line{
          stroke-width: 1.2;
        }
      }
    }
    &.open{
      bottom: 0;
    }
  }
</style>