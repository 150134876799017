<template>
  <section class="home-content">
    <div class="container">
      <h1>Vítejte!</h1>
      <p>
        Vložení nemovitosti je snadné, jednoduché a rychlé! Za 10 minut máte hotovo. Za vložení inzerátu nic neplatíte.
      </p>
      <div class="no-advert">
        <!--<picture>
          <img alt="house" src="../assets/images/no-inzerat.png">
        </picture>-->
        <div class="slideshow" data-uk-slideshow>
          <ul class="uk-slideshow-items">
            <li>
              <video src="../assets/video/hp-video.mp4" controls playsinline muted data-uk-cover></video>
            </li>
          </ul>
        </div>
        <p v-if="countAdverts(getId) == 0">Zatím nemáte přidané žádné inzeráty</p>
        <p v-if="countAdverts(getId) == 1">Máte {{ countAdverts(getId) }} inzerát</p>
        <p v-if="countAdverts(getId) > 1 && countAdverts(getId) < 5">Máte {{ countAdverts(getId) }} inzeráty</p>
        <p v-if="countAdverts(getId) >= 5">Máte {{ countAdverts(getId) }} inzerátů</p>
      </div>
      <p class="center">
        <a @click="createAdvert" class="button">Vložit nemovitost<img src="../assets/images/plus.svg" data-uk-svg /></a>
      </p>
      <advert-list></advert-list>
    </div>
  </section>
</template>

<script>
import advertList from "@/components/advertList";
import { mapGetters } from "vuex";
import { askForPermissionToReceiveNotifications } from "../push-notification";

export default {
  name: "Home",
  components: {
    advertList,
  },
  computed: {
    ...mapGetters(["getId", "countAdverts"]),
  },
  methods: {
    createAdvert() {
      let add = this.$store.dispatch("createNewAdvert");
      add.then((add) => this.$router.push({ name: "firstStep", params: { id: add.id } }));
    },
    askFormPermission() {
      askForPermissionToReceiveNotifications();
      this.randomNotification();
    },
    randomNotification() {
      const notifTitle = "Test title";
      const notifBody = `Created by ny.`;
      const options = {
        body: notifBody,
        icon: null,
      };
      new Notification(notifTitle, options);
      console.log("after notification");
    },
  },
  async created() {
    /*this.axios.get('https://www.realitni-samoobsluha.cz/pages/valuo.php?mat=0.05664222184767187&uzitna_plocha=2&idecko=34424&stav=1&_=1650620810635')
      .then(function(resp){
        console.log(resp);
      }).catch((e) => {
        console.log(e);
    })*/
    this.$store.dispatch("deleteRemovedAdverts");
  },
};
</script>

<style lang="less">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
section.home-content {
  .container {
    position: relative;
    max-width: 42.8rem;
    height: 93vh;
    overflow: auto;
    margin: auto;
    background: #f2f2f2;
    box-sizing: border-box;
    padding-bottom: 4rem;
    h1 {
      font-family: "Poppins";
      font-weight: 700;
      font-size: 5rem;
      line-height: 7.6rem;
      text-align: center;
      color: #7c7c7b;
      margin-top: 0;
      margin-bottom: 1rem;
    }
    > p {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2.3rem;
      text-align: center;
      max-width: 33rem;
      margin: auto;
      margin-top: 0;
      margin-bottom: 4rem;
      &.center {
        text-align: center;
        .button {
          margin: auto;
        }
      }
    }
    .no-advert {
      picture {
        img {
          display: block;
          margin: auto;
          max-height: 17.1rem;
        }
      }
      .video-pack {
        position: relative;
        max-width: 42.8rem;
        width: 100%;
        height: 24rem;
        overflow: hidden;
        video {
          position: absolute;
          z-index: 0;
          object-fit: cover;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &::-webkit-media-controls {
            display: none !important;
          }
        }
      }
      // .slideshow{
      //   .uk-slideshow-items{
      //     li{

      //     }
      //   }
      // }
      p {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 2rem;
        line-height: 3rem;
        color: #7c7c7b;
        text-align: center;
        max-width: 23.8rem;
        margin: auto;
        margin-top: 4rem;
        margin-bottom: 2.6rem;
      }
    }
  }
}
</style>
