<template>
  <div class="advert-contents">
    <h2>Rozpracované</h2>
    <advert-card
      v-for="(advert, index) in tmpAdverts"
      :item="advert"
      :index="index"
      :key="advert.id"
    ></advert-card>
    <h2>Aktivované</h2>
    <advert-card
      v-for="(advert, index) in sendAdverts"
      :item="advert"
      :index="index"
      :key="advert.id"
    ></advert-card>
  </div>
</template>

<script>
import advertCard from "@/components/advertCard";
import {mapGetters} from "vuex";

export default {
  name: "advertList",
  created() {
  },
  components:{
    advertCard
  },
  computed:{
    ...mapGetters([
      'tmpAdverts',
      'sendAdverts'
    ])
  }
}
</script>

<style lang="less" scoped>
h2{
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 3rem;
  line-height: 4.6rem;
  color: #7C7C7B;
  text-align: center;
  margin-top: 0;
  margin-bottom: 2.2rem;
}
</style>