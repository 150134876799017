import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import multistep from './modules/multistep';

export default createStore({
    modules: {
        auth,
        multistep
    },
    plugins: [createPersistedState()]
})