<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">{{ header }}</slot>
          </div>

          <div class="modal-body">
            <slot name="body">{{ body }}</slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button
                class="button small uk-margin-large-top red"
                @click="$emit('close')"
              >OK</button>
            </slot>
          </div>

        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "modal",
  props: {
    show: Boolean,
    header: String,
    body: String,
  }
}
</script>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }

  .modal-header{
    margin-top: 0;
    color: #42b983;
    margin-bottom:5rem;
    text-align: center;
  }

  .modal-body {
    font-size: 1.325em;
    margin: 20px 0;
  }
  
  .modal-footer{
    width:100%;
    display:flex;
    justify-content: center;
  }
  
</style>