<template>
  <div class="advert">
    <div class="top">
      <picture>
        <img alt="advert-picture" :src="baseImage">
      </picture>
      <span v-if="!item.remoteId && !item.activated" class="label">rozpracováno</span>
      <span v-if="item.remoteId && !item.activated" class="label yes">uloženo na server</span>
      <span v-if="item.activated" class="label yes">Aktivováno</span>
    </div>
    <div class="bottom">
      <h3>{{ item.steps[1].nazev }}</h3>
      <p class="price">{{ item.steps[2].price }}</p>
      <div class="infoadvert">
        <p :class="{size: getDruh }">{{ getDruh }}</p>
        <div class="info-pack">
          <p class="icon" @click="show = !show"><img alt="advert-info" src="../assets/images/inzerat-info.svg"
                                                     data-uk-svg></p>
          <transition name="bounce" v-if="!item.activated">
            <p class="info-content" v-if="show">
              <router-link :to="{name: 'firstStep', params: {id: item.id}}" class="link">Upravit</router-link>
              <a @click="remove" class="link">Smazat</a>
              <!--<a @click="updateFromApi" class="link">Aktualizovat ze serveru</a>-->
              <a @click="show = !show" class="link">Zavřít</a>
            </p>
          </transition>

          <transition name="bounce" v-if="item.activated">
            <p class="info-content" v-if="show">
              <router-link :to="{name: 'viewonly', params: {id: item.id}}" class="link">Náhled</router-link>
              <router-link :to="{name: 'changeadvert', params: {id: item.id, requestType: 1}}" class="link">Upravit</router-link>
              <router-link :to="{name: 'changeadvert', params: {id: item.id, requestType: 2}}" class="link">Zrušit</router-link>
              <!--<a @click="updateFromApi" class="link">Aktualizovat ze serveru</a>-->
              <a @click="show = !show" class="link">Zavřít</a>
            </p>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "advertCard",
  props: ['item'],
  computed:{
    ...mapGetters([
      'getImages'
    ]),
    getDruh(){
      let idDruh = this.item.steps[1].druh;
      if( idDruh && this.druh[idDruh] )
      {
        return this.druh[idDruh];
      }
      return null;
    },
    getPrice(){
      let price = this.item.steps[2].price;
      if( price )
      {
        let idMeny = this.item.steps[2].mena;
        return price + ' ' + this.meny[idMeny];
      }
      return null;
    }
  },
  methods:{
    remove(){
      let isExecuted = confirm('Opravdu chcete smazat vaše lokální dat? Data na serveru nebudou smazána. Opětovně si inzerát můžete stáhnout v pravém menu.');
      if( isExecuted )
      {
        this.$store.dispatch('removeAdvert', this.item.id);
      }
    },

    updateFromApi(){
      let isExecuted = confirm('Opravdu si přejete aktualizovat inzerát daty ze serveru? Toto přemaže všechny vaše lokální úpravy, neodeslané na server!');
      if( isExecuted )
      {
        this.$store.dispatch('updateAdvertFromApi', this.item.remoteId).then(response => {
          console.log(response);
        });
      }
    }
  },
  async created() {
    let images = await this.getImages(this.item.id);
    if( images ){
      if( images[1] ){
        if (images[1].base){
          this.baseImage = images[1].base;
        }
      }
    }

    if( this.baseImage == null ){
      this.baseImage = require('@/assets/images/inzerat.png');
    }
  },
  data() {
    return {
      show: false,
      baseImage: null,
      meny:{
        1: "v kč",
        4: "kč/m2",
        11: "eur",
        44: "eur/m2",
      },
      druh: {
        15: 'Rodinný',
        16: 'Vila',
        560: 'Chata',
        188: 'Jiný',
        112: "1+kk",
        113: "1+1",
        122: "2+kk",
        123: "2+1",
        125: "3+kk",
        126: "3+1",
        132: "4+kk",
        133: "4+1",
        135: "5+kk",
        136: "5+1",
        137: "6",
        33: "pro bydlení",
        34: "pro komerční výstavbu",
        31: "zemědělská půda",
        38: "les",
        32: "zahrada",
        35: "rybníky",
        36: "ostatní",
        27: "kanceláře",
        28: "sklady",
        25: "výroba",
        21: "obchodní prostory",
        221: "restaurace",
        222: "ubytování",
        23: "zemědělské objekty",
        224: "jiné",
        17: "činžovní dům",
        41: "garáž",
        410: "garážové stání",
        420: "vinný sklep",
        42: "ostatní"
      }
    }
  },
}
</script>

<style lang="less" scoped>
.advert {
  position: relative;
  display: block;
  background: #fff;
  overflow: hidden;
  max-width: 38rem;
  width: 100%;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
  margin: auto;
  margin-bottom: 2rem;

  .top {
    position: relative;
    display: block;
    overflow: hidden;
    max-width: 38rem;
    widows: 100%;
    height: 14.1rem;

    picture {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .label {
      position: absolute;
      top: 1.7rem;
      right: 1.7rem;
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2.3rem;
      color: #7C7C7B;
      padding: 0.9rem 4rem 0.9rem 2.1rem;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background: fadeout(#fff, 20);
      -webkit-border-radius: 5rem;
      -moz-border-radius: 5rem;
      border-radius: 5rem;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 1.5rem;
        bottom: 0;
        margin: auto;
        width: 0.8rem;
        height: 0.8rem;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: #EFAD3C;
        z-index: 2;
      }

      &.yes {
        &:before {
          background: #2FAC66;
        }
      }
    }
  }

  .bottom {
    position: relative;
    padding: 2rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    h3 {
      font-family: 'Poppins';
      font-weight: 700;
      font-size: 2rem;
      line-height: 3rem;
      color: #7C7C7B;
      text-align: left;
      margin-top: 0;
      margin-bottom: 1.1rem;
    }

    .price {
      font-family: 'Poppins';
      font-weight: 700;
      font-size: 2rem;
      line-height: 3rem;
      color: #03A3BD;
      text-align: left;
      margin-top: 0;
      margin-bottom: 1.1rem;
    }

    .infoadvert {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .size {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 2rem;
        line-height: 3rem;
        color: #7C7C7B;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.7rem 3rem;
        border: 1px solid #7C7C7B;
        -webkit-border-radius: 5rem;
        -moz-border-radius: 5rem;
        border-radius: 5rem;
        margin-top: 0;
        margin-bottom: 0;
      }

      .info-pack {
        position: relative;

        .icon {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 5.4rem;
          height: 5.4rem;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          margin-bottom: 0;

          svg {
            g {
              circle {
                fill: #03A3BD;
              }
            }
          }

          &:hover {
            cursor: pointer;
            background: #03A3BD;

            svg {
              g {
                circle {
                  fill: #fff;
                }
              }
            }
          }
        }

        .info-content {
          position: absolute;
          bottom: 0;
          background: #fff;
          right: 0;
          width: 20rem;
          min-height: 7rem;
          z-index: 3;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 2rem;
          -webkit-border-radius: 2rem;
          -moz-border-radius: 2rem;
          border-radius: 2rem;
          -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          //display: none;
          .link{
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #03A3BD;
            margin-bottom: 0.5rem;
          }
          button {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 2.2rem;
            color: #03A3BD;
            border: 0;
            background: transparent;
            cursor: pointer;

            &:hover {
              color: #2FAC66;
            }
          }
        }
      }
    }
  }
}

</style>