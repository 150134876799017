<template>
  <SwUpdatePopup />
  <topNav
    v-if="isAuthenticated"
    @openVideo="openVideoPanel()"
    @hashModal="openHashModal"
    :isHash="hash"
    @updateAdverts="updateAdverts"
  />
  <router-view />
  <Nav v-if="isAuthenticated" :showNavbar="showNavbar" />
  <VideoPanel v-if="showVideo" @closeVideo="closeVideoPanel()" />
  <teleport to="body">
    <Modal :show="showSpinner">
      <template #header>
        <h3>{{ spinnerMessage }}</h3>
      </template>
      <template #body>
        <div v-if="!advertsDownloaded" class="spinner-wrapper">
          <img src="./assets/images/spinner.svg" alt="" class="spinner" />
        </div>
      </template>
      <template #footer>
        <div v-if="!advertsDownloaded"></div>
        <button
          v-if="advertsDownloaded"
          class="button small uk-margin-large-top red"
          @click="
            this.showSpinner = false;
            this.advertsDownloaded = false;
          "
        >
          OK
        </button>
      </template>
    </Modal>
  </teleport>
  <teleport to="body">
    <Modal :show="showHashModal">
      <template #header>
        <h3 v-if="hash">Opravdu chcete zrušit svůj účet?</h3>
        <h3 v-if="!hash">Nastala nějaká chyba, prosím zkuste akci provést později.</h3>
      </template>
      <template #footer>
        <div class="uk-flex uk-flex-between">
          <button v-if="!hash" class="button white small uk-margin-large-top red" @click="this.showHashModal = false">
            Zavřít
          </button>
          <button
            v-if="hash"
            class="button white small uk-margin-large-top red"
            @click="
              this.hash = '';
              this.showHashModal = false;
            "
          >
            Chci účet ponechat
          </button>
          <button v-if="hash" @click="navigate(hash, true)" class="button small uk-margin-large-top">
            Chci zrušit účet
          </button>
        </div>
      </template>
    </Modal>
  </teleport>
</template>

<script lang="js">
import Nav from './components/Nav.vue'
import SwUpdatePopup from './components/SWUpdatePopup.vue'
import topNav from './components/topNav.vue'
import VideoPanel from './components/videoPanel.vue'
import InstallPupUp from "@/components/InstallPupUp";
import Modal from './components/general/modal.vue';

export default {
  name: "app",
  components: {
    Nav,
    SwUpdatePopup,
    topNav,
    InstallPupUp,
    VideoPanel,
    Modal
  },
  computed:{
    isAuthenticated(){
      return this.$store.getters.isAuthenticated;
    },
  },
  errorCaptured(error,component, message){
    console.log(error);
    console.log(component)
    console.log(message)
  },
  data(){
    return{
      showNavbar: true,
      showVideo:false,
      showHashModal:false,
      hash:'',
      lastScrollPosition: 0,
      showSpinner: false,
      spinnerMessage:'',
      advertsDownloaded:false,
    }
  },
  watch: {
    $route( to, from ){
      this.showNavbar = true;
      this.lastScrollPosition = 0;
    }
  },
  methods: {
    openVideoPanel(e){
      this.showVideo = true;
      setTimeout(()=>{
        document.getElementById('myVideo').classList.add('open')
      },250)
    },
    closeVideoPanel(e){
      document.getElementById('myVideo').classList.remove('open')
      setTimeout(()=>{
      this.showVideo = false;
      },250)
    },
    handleScroll(event) {
      const currentScrollPosition = event.srcElement.scrollTop;
      if( typeof currentScrollPosition == 'undefined'){
        this.showNavbar = true;
        return;
      }

      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        this.showNavbar = true;
        return;
      }

      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 10) {
        return;
      }

      // Here we determine whether we need to show or hide the navbar
      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = currentScrollPosition
    },
    // modal pro redirect na zrušení pčtu
    openHashModal(data){
      if(data.status === 'ok'){
        this.hash = data.hash;
        this.showHashModal=true;
      } else if(data.status === 'error'){
        this.showHashModal=true;
      }

    },
    // navigace na stránku pro zrušení účtu
    navigate(href, newTab) {
      var a = document.createElement('a');
      a.href = href;
      if (newTab) {
         a.setAttribute('target', '_blank');
       }
      a.click();
    },
    async updateAdverts(){
      this.spinnerMessage = 'Vaše inzeráty se načítají'
      this.showSpinner = true;
      try{
      const response = await this.$store.dispatch("updateAdverts");
      let adverts = '';
      if(response.length === 0 || response.length > 4){
        adverts = 'inzerátů bylo aktualizováno'
      }
      if(response.length === 1){
        adverts = 'inzerát byl aktualizován'
      }
      if(response.length > 1 && response.length <= 4){
        adverts = 'inzeráty byly aktualizovány'
      }

      if(response.length === undefined && adverts === ''){
        throw new Error('Internet connection lost');
      }
      this.spinnerMessage = `${response.length} ${adverts}.`
      this.advertsDownloaded = true;
      } catch(err){
        console.log(err)
        if(err){
          if(!navigator.onLine){
            this.spinnerMessage = 'Nejste připojeni k internetu. Zkuste prosím inzeráty aktualizovat později.'
          } else{
            this.spinnerMessage = 'Něco se nepovedlo. Zkuste prosím inzeráty aktualizovat později.'
          }
          this.advertsDownloaded = true;
        }
      }
    }

  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }
}
</script>

<style lang="less">
/*obecné styly*/
html {
  background: rgba(143, 187, 105, 1);
  background: -moz-linear-gradient(top, rgba(143, 187, 105, 1) 0%, rgba(47, 172, 102, 1) 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(143, 187, 105, 1)),
    color-stop(100%, rgba(47, 172, 102, 1))
  );
  background: -webkit-linear-gradient(top, rgba(143, 187, 105, 1) 0%, rgba(47, 172, 102, 1) 100%);
  background: -o-linear-gradient(top, rgba(143, 187, 105, 1) 0%, rgba(47, 172, 102, 1) 100%);
  background: -ms-linear-gradient(top, rgba(143, 187, 105, 1) 0%, rgba(47, 172, 102, 1) 100%);
  background: linear-gradient(to bottom, rgba(143, 187, 105, 1) 0%, rgba(47, 172, 102, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8fbb69', endColorstr='#2fac66', GradientType=0);
}

:root {
  font-size: 62.5%;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("assets/fonts/poppins-v15-latin-ext_latin-regular.woff2") format("woff2"),
    url("assets/fonts/poppins-v15-latin-ext_latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("assets/fonts/poppins-v15-latin-ext_latin-700.woff2") format("woff2"),
    url("assets/fonts/poppins-v15-latin-ext_latin-700.woff") format("woff");
}

@font: "Poppins";
@white: rgb(255, 255, 255);
@black: rgb(124, 124, 123);
@green: rgb(4, 172, 102);
@blue: rgb(3, 163, 189);

.button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 27.2rem;
  height: 5rem;
  font-family: @font;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  border-radius: 5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid @green;
  color: @white;
  background: @green;
  outline: 0;
  cursor: pointer;
  text-decoration: none !important;

  svg {
    display: inline-block;
    margin-left: 1rem;
  }

  &:hover {
    background: @white;
    color: @green;
  }

  &.white {
    background: @white;
    border-color: @green;
    color: @green !important;

    &:hover {
      background: @green;
      color: @white !important;
    }
  }

  &.small {
    font-size: 1.5rem;
    line-height: 1.5rem;
    height: 4.3rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: unset;
    text-align: center;
    padding: 0 2rem;
  }
}

.notification {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 42.8rem;
  background: #ac2f2f;
  z-index: 1001;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 6rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 2rem;
  text-align: center;
  opacity: 0.5;
  -webkit-animation: fade 8s linear;
  animation: fade 8s linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 0;
}

.notificationSucces {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 42.8rem;
  background: #04ac66;
  z-index: 1001;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 6rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 2rem;
  text-align: center;
  opacity: 0.8;
  -webkit-animation: fade 6s linear;
  animation: fade 6s linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 0;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(-7rem);
  }
  10% {
    opacity: 0.8;
    transform: translateY(0);
  }
  90% {
    opacity: 0.5;
  }
  95% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-7rem);
  }
}

/*obecné styly end*/
#app {
  position: relative;
  font-family: @font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@media (orientation: landscape) and (display-mode: standalone) {
  section.login .container .login-content {
    height: 100vh !important;
  }

  section.login .container .login-content.open {
    bottom: 47vh !important;
  }

  section.login .container .login-content form .row.end {
    margin-top: 2.3rem !important;
  }

  section.login .container .login-content form .row.end a {
    margin-top: 1rem !important;
  }

  section.recovery form .row.end,
  section.register form .row.end {
    margin-top: -0.7rem !important;

    .button {
      margin: auto;
    }
  }
}
.spinner-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
}
.spinner {
  max-height: 5rem;
}
</style>
