<template>
  <section id="footer" class="nav" :class="{'navbar-hidden': !showNavbar}">
    <router-link to="/"><span class="circle"><img src="../assets/images/ico-nav-home.svg" alt="home" data-uk-svg></span><span>Domů</span>
    </router-link>
    <router-link to="/adverts">
      <span class="circle">
        <img src="../assets/images/ico-nav-advert.svg" alt="advert" data-uk-svg>
      </span>
      <span>Moje inzeráty</span>
    </router-link>
    <!--<router-link to="/about"><span class="circle"><img src="../assets/images/ico-nav-settings.svg" alt="settings"
                                                       data-uk-svg></span><span>Moje údaje</span></router-link>-->
    <router-link to="/takers" class="blue">
      <span class="circle">
        <img src="../assets/images/zajemci.svg" alt="home" data-uk-svg>
      </span>
      <span>Zájemci</span>
    </router-link>
    <a @click="createAdvert" class="green">
      <span class="circle">
        <img src="../assets/images/ico-nav-plus.svg" alt="plus" data-uk-svg>
      </span>
      <span>Vložit nemovitost</span>
    </a>
  </section>
</template>

<script>
export default {
  props: ['showNavbar'],
  name: "Nav",
  methods: {
    createAdvert() {
      let add = this.$store.dispatch('createNewAdvert');
      add.then((add) => this.$router.push({name: 'firstStep', params: {id: add.id}}));
    },
  },
}
</script>

<style lang="less" scoped>
section.navbar-hidden {
  display: none !important;
}

section.nav {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  width: 100%;
  max-width: 42.8rem;
  height: 6.5rem;
  margin: auto;
  background: #F2F2F2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
  z-index: 90;
  margin-bottom: -1rem;

  a {
    position: relative;
    top: -2rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 8.7rem;
    &:last-child{
      min-width: 10.3rem;
      max-width: 12rem;
    }
    span {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: #7C7C7B;

      &.circle {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 4.4rem;
        height: 4.4rem;
        background: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
        margin-bottom: 0.5rem;
        color: #03A3BD;

        &:hover {
          background: #03A3BD;
          color: #fff;
        }
      }
    }

    &:hover {
      text-decoration: none;
    }

    &.green {
      span {
        color: #2FAC66;

        &.circle {
          background: #2FAC66;
          color: #fff;

          &:hover {
            color: #2FAC66;
            background: #fff;
          }
        }
      }
    }
    &.blue{
      span {
        color: #7C7C7B;

        &.circle {
          background: #03A3BD;
          color: #fff;

          &:hover {
            color: #03A3BD;
            background: #fff;
          }
        }
      }
    }
  }
}
</style>