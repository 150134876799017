<template>
  <section class="uk-section uk-section-primary uk-light">
    <button @click="manualInstall()">Instalovat</button>
    <p v-if="installed">Aplikace je nainstalována</p>
    <p v-if="!installed">Aplikace není nainstalována</p>

    <p v-if="!beforeInstall">Before install nespuštěna</p>
    <p v-if="beforeInstall">Before install spuštěna</p>

    <p v-if="isIos()">Je Ios</p>
    <p v-if="!isIos()">Není Ios</p>
    <p v-if="isStandaloneMode()"> Je Standalone</p>
    <p v-if="!isStandaloneMode()">Není standalon</p>
    <div v-if="deferredPrompt" class="container uk-container">
      <h3>Install</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
        <button @click="dismiss" class="uk-button uk-button-danger">Dismiss</button>
        <button @click="install" class="uk-button uk-button-primary">Instal</button>
    </div>

  </section>

</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "InstallPupUp",
  data() {
    return {
      deferredPrompt: null,
      showInstallMessage: false,
      installed: false,
      beforeInstall: false,
    }
  },

  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();

      this.beforeInstall = true;
      // Stash the event so it can be triggered later.
      if( Cookies.get("add-to-home-screen") === undefined ){
        this.deferredPrompt = e;
      }
    })

    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
      this.installed = true;
    })

    console.log(this.deferredPrompt);
  },

  methods: {
    async dismiss(){
      Cookies.set("add-to-home-screen", null, { expires: 15});
      this.deferredPrompt = null;
    },
    async install(){
      this.deferredPrompt.prompt();
    },

    isIos(){
      let userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    },

    isStandaloneMode(){
      return ('standalone' in window.navigator && window.navigator.standalone);
    },

    manualInstall(){
      console.log(this.deferredPrompt);
    }


  }
}
</script>

<style lang="less" scoped>
  section {
    .container {
      position: relative;
      max-width: 42.8rem;
      margin: auto;
    }
  }
</style>