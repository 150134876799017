import axios from "./main.js";
import store from "@/store";
import EventBus from "../libs/event-bus";
const applicationServerKey = "BMBlr6YznhYMX3NgcWIDRxZXs0sh7tCv7_YCsWcww0ZCv9WGg-tRCXfMEHTiBPCksSqeve1twlbmVAZFv7GSuj0";

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    const token = await messaging.getToken();
    console.log("Your token is:", token);

    return token;
  } catch (error) {
    console.error(error);
  }
};

export const isPushSupported = () => {
  if (!("serviceWorker" in navigator)) {
    return false;
  }

  if (!("PushManager" in window)) {
    return false;
  }

  if (!("showNotification" in ServiceWorkerRegistration.prototype)) {
    return false;
  }
  return true;
};

export const isNotificationsAllowed = () => {
  return Notification.permission === "granted";
};

export const isBlockedByUser = () => {
  return Notification.permission === "denied";
};

export const pushSubscribe = () => {
  return checkNotificationPermission()
    .then(() => {
      //console.log("returned granted");
      const ready = navigator.serviceWorker.ready;
      // console.log(ready);
      // console.log(store);
      return ready;
    })
    .then((serviceWorkerRegistration) => {
      // console.log(serviceWorkerRegistration);
      // console.log("subscribing");
      return serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
      });
    })
    .then((subscription) => {
      //console.log(subscription);
      // Subscription was successful
      // create subscription on your server
      return push_sendSubscriptionToServer(subscription);
    })
    .then((subscription) => {
      console.log(subscription);
      return true;
    }) // update your UI
    .catch((e) => {
      if (Notification.permission === "denied") {
        // The user denied the notification permission which
        // means we failed to subscribe and the user will need
        // to manually change the notification permission to
        // subscribe to push messages
        console.warn("Notifications are denied by the user.");
        //changePushButtonState('incompatible');
      } else {
        // A problem occurred with the subscription; common reasons
        // include network errors or the user skipped the permission
        console.error("Impossible to subscribe to push notifications", e);
        //changePushButtonState('disabled');
      }
      return false;
    });
};

function checkNotificationPermission() {
  return new Promise((resolve, reject) => {
    if (Notification.permission === "denied") {
      return reject(new Error("Push messages are blocked."));
    }

    if (Notification.permission === "granted") {
      return resolve();
    }

    if (Notification.permission === "default") {
      return Notification.requestPermission().then((result) => {
        if (result !== "granted") {
          reject(new Error("Bad permission result"));
        } else {
          console.log("granted");
          resolve();
        }
      });
    }

    return reject(new Error("Unknown permission"));
  });
}

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function push_sendSubscriptionToServer(subscription) {
  //console.log(subscription);
  const key = subscription.getKey("p256dh");
  const token = subscription.getKey("auth");
  const encoding = (PushManager.supportedContentEncodings || ["aesgcm"])[0];
  let userToken = store.getters.tokenIsValid ? store.getters.getToken : await store.dispatch("getToken");
  //const userToken = await store.dispatch("getToken");
  const data = {
    id: store.getters.getId,
    token: userToken,
    notifikace: {},
  };

  const browserId = detectBrowser();
  const browser = {
    endpoint: subscription.endpoint,
    auth: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
    contentEncoding: encoding,
    p256dh: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
  };

  const notifications = {};
  notifications[browserId] = browser;
  data.notifikace = notifications;
  // console.log(data);
  return await axios.axios.post("notifikace/", data).then((response) => {
    console.log(response);
    if (response.data.info.code != 200) {
      throw Error("Nepodařilo se přihlásit k subscription");
    }
    EventBus.$emit("subscriptionRecalculate");
    return subscription;
  });
  //return subscription;
}

function detectBrowser() {
  if (navigator.userAgent.indexOf("Firefox") != -1) {
    return 2;
  }

  if (navigator.userAgent.indexOf("Chrome") != -1) {
    return 1;
  }

  if (navigator.userAgent.indexOf("Edg") != -1) {
    return 3;
  }
}
