import './smap-external.js';
import EventBus from "../event-bus";
Loader.load(null, {suggest: true});

function initGeocoder()
{
  console.log('geocoder init');
  const element = document.getElementById('gpsAvailable');
  if( "geolocation" in navigator && element )
  {
    element.addEventListener('click', function (){
      EventBus.$emit('gps-start', true);

      let config = { enableHighAccuracy: true,
        timeout: 300000,
        maximumAge: 0};

      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        let lat = position.coords.latitude;
        let long = position.coords.longitude;

        if( !lat ){
          alert('GPS není zapnutá')
        }

        let coords = SMap.Coords.fromWGS84(long, lat);

        new SMap.Geocoder.Reverse(coords, function( geocoder ){
          let rawData = geocoder.getResults();
          if( rawData.items )
          {
            let label = rawData.label;

            let address = {
              'place': rawData.items.find(e => e.type == 'addr') ? rawData.items.find(e => e.type == 'addr').name : null,
              'street': rawData.items.find(e => e.type == 'stre') ? rawData.items.find(e => e.type == 'stre').name : null,
              'ob': rawData.items.find(e => e.type == 'muni') ? rawData.items.find(e => e.type == 'muni').name : null,
              'cbo': rawData.items.find(e => e.type == 'ward') ? rawData.items.find(e => e.type == 'ward').name : null,
              'psc': label.match('\\d{3} ?\\d{2}' ) ? label.match('\\d{3} ?\\d{2}' )[0] : null,
              'distName': rawData.items.find(e => e.type == 'dist') ? rawData.items.find(e => e.type == 'dist').name.replace('Okres ', '') : null
            };

            let matches = address.place ? address.place.match('^(.*[^0-9]+) (([1-9][0-9]*)\\/)?([1-9][0-9]*[a-cA-C]?)$') : null;
            address.cp = matches ? matches[3] : null;
            address.co = matches ? matches[4] : null;

            let coords = rawData.items[0];
            address.latitude = coords.coords.y;
            address.longitude = coords.coords.x;
            address.zadal = label;
            EventBus.$emit('gps', address);
          }
        }, );



      }, positionError, config);
    })
  }
}
window.addEventListener('load', initGeocoder, false );

function positionError( error ) {

  switch ( error.code ) {
    case error.PERMISSION_DENIED:

      console.error( "User denied the request for Geolocation." );
      break;

    case error.POSITION_UNAVAILABLE:

      console.error( "Location information is unavailable." );
      break;

    case error.TIMEOUT:

      console.error( "The request to get user location timed out." );
      break;

    case error.UNKNOWN_ERROR:

      console.error( "An unknown error occurred." );
      break;
  }
}

export default {
  initGeocoder
}