import okres from "../public/ciselniky/okres";
import katastr from "../public/ciselniky/katastr";
import obce from "../public/ciselniky/obce";
import store from "../src/store/index.js";
import axios from "../src/main.js"

const vuexToApi = function (add)
{
  // console.log(add)
  let zakazka = {
    zadanytyp: add.steps[1].zadanytyp,
    druh: add.steps[1].druh,
    nazev: add.steps[1].nazev,
    loginid: add.loginId,
    vlastnictvi: add.steps[1].vlastnictvi,
    popis_cz: add.steps[1].popis_cz,
    zadal: add.address.place
  }
  if (add.steps[1].zadanytyp == 6) {
    zakazka.pocet_pokoju = add.steps[1].pocet_pokoju;
    zakazka.uzit_plocha = add.steps[2].uzit_plocha;
    zakazka.plochapozem = add.steps[2].plochapozem;
    zakazka.stav = add.steps[2].stav;
    zakazka.postavenoz = add.steps[2].postavenoz;
    zakazka.poloha = add.steps[2].poloha;
    zakazka.vytapeni = add.steps[2].vytapeni;

    zakazka.plocha_sklep = add.steps[2].plocha_sklep;
    zakazka.plocha_lodzie = add.steps[2].plocha_lodzie;
    zakazka.pocet_garaz = add.steps[2].pocet_garaz;
    zakazka.bazen = add.steps[2].bazen;
    zakazka.plocha_balkon = add.steps[2].plocha_balkon;
    zakazka.plocha_terasa = add.steps[2].plocha_terasa;
    zakazka.park_pocet = add.steps[2].park_pocet;
  }

  if (add.steps[1].zadanytyp == 4) {
    zakazka.cislo_jednotky = add.steps[2].cislo_jednotky;
    zakazka.pater = add.steps[2].pater;
    zakazka.patro = add.steps[2].patro;
    zakazka.uzit_plocha = add.steps[2].uzit_plocha;
    zakazka.stav = add.steps[2].stav;
    zakazka.postavenoz = add.steps[2].postavenoz;
    zakazka.vytah = add.steps[2].vytah;
    zakazka.vytapeni = add.steps[2].vytapeni;
    zakazka.plocha_sklep = add.steps[2].plocha_sklep;
    zakazka.plocha_lodzie = add.steps[2].plocha_lodzie;
    zakazka.pocet_garaz = add.steps[2].pocet_garaz;
    zakazka.plocha_balkon = add.steps[2].plocha_balkon;
    zakazka.plocha_terasa = add.steps[2].plocha_terasa;
    zakazka.park_pocet = add.steps[2].park_pocet;
  }
  if (add.steps[1].zadanytyp == 3) {
    zakazka.id_parcela = add.steps[2].id_parcela;
    zakazka.celkova_plocha = add.steps[2].celkova_plocha;
    zakazka.inzsite = add.steps[2].inzsite;
    zakazka.odpad = add.steps[2].odpad;
  }

  if (add.steps[1].zadanytyp == 2) {
    zakazka.uzit_plocha = add.steps[2].uzit_plocha;
    zakazka.plochapozem = add.steps[2].plochapozem;
    zakazka.stav = add.steps[2].stav;
    zakazka.postavenoz = add.steps[2].postavenoz;
    zakazka.poloha = add.steps[2].poloha;
    zakazka.vytapeni = add.steps[2].vytapeni;
    zakazka.plocha_terasa = add.steps[2].plocha_terasa;
    zakazka.pocet_garaz = add.steps[2].pocet_garaz;
    zakazka.park_pocet = add.steps[2].park_pocet;
  }

  if (add.steps[1].zakazka == 11) {
    zakazka.uzit_plocha = add.steps[2].uzit_plocha;
    zakazka.plochapozem = add.steps[2].plochapozem;
    zakazka.stav = add.steps[2].stav;
    zakazka.postavenoz = add.steps[2].postavenoz;
  }

  //faktické
  zakazka.fakt01 = add.steps[2].fakt01 ? 1 : 0;
  zakazka.fakt02 = add.steps[2].fakt02 ? 1 : 0;
  zakazka.fakt03 = add.steps[2].fakt03 ? 1 : 0;
  zakazka.fakt04 = add.steps[2].fakt04 ? 1 : 0;
  zakazka.fakt05 = add.steps[2].fakt05 ? 1 : 0;
  zakazka.fakt07 = add.steps[2].fakt07 ? 1 : 0;
  zakazka.fakt09 = add.steps[2].fakt09 ? 1 : 0;
  zakazka.fakt10 = add.steps[2].fakt10 ? 1 : 0;
  

  //právní
  zakazka.pakt01 = add.steps[2].pakt01 ? 1 : 0;
  zakazka.pakt02 = add.steps[2].pakt02 ? 1 : 0;
  zakazka.pakt03 = add.steps[2].pakt03 ? 1 : 0;
  zakazka.pakt04 = add.steps[2].pakt04 ? 1 : 0;
  zakazka.pakt05 = add.steps[2].pakt05 ? 1 : 0;
  zakazka.pakt06 = add.steps[2].pakt06 ? 1 : 0;
  zakazka.pakt07 = add.steps[2].pakt07 ? 1 : 0;
  zakazka.pakt08 = add.steps[2].pakt08 ? 1 : 0;
  zakazka.pakt10 = add.steps[2].pakt10 ? 1 : 0;
  zakazka.pakt11 = add.steps[2].pakt11 ? 1 : 0;
  

  //vecnné břemeno
  zakazka.cakt01 = add.steps[2].cakt01 ? 1 : 0;
  zakazka.cakt02 = add.steps[2].cakt02 ? 1 : 0;
  zakazka.cakt03 = add.steps[2].cakt03 ? 1 : 0;
  zakazka.cakt04 = add.steps[2].cakt04 ? 1 : 0;
  zakazka.cakt05 = add.steps[2].cakt05 ? 1 : 0;
  zakazka.cakt06 = add.steps[2].cakt06 ? 1 : 0;
  zakazka.cakt07 = add.steps[2].cakt07 ? 1 : 0;
  zakazka.cakt08 = add.steps[2].cakt08 ? 1 : 0;

  

  zakazka.okres = add.address.okres;
  zakazka.id_mesto = add.address.id_mesto;
  zakazka.id_katastr = add.address.id_katastr;

  zakazka.el = add.steps[2].el;
  zakazka.cena = add.steps[2].cena;
  zakazka.cenatext = add.steps[2].cenatext;
  zakazka.mena = add.steps[2].mena;
  zakazka.e1 = add.steps[2].energ ? add.steps[2].energ : 7;
  return zakazka;
}

const apiToVuex = async function ( remoteAdd )
{
  // console.log(remoteAdd)
  let zakazka = {
    id: null,
    remoteId: remoteAdd.id,
    loginId: remoteAdd.loginid,
    steps: {
      1: {
        zadanytyp: remoteAdd.zadanytyp,
        druh: remoteAdd.druh,
        nazev: remoteAdd.nazev || '',
        vlastnictvi: remoteAdd.vlastnictvi,
        popis_cz: remoteAdd.popis_cz || '',
      },
      2: {
        //faktické
        fakt01: remoteAdd.fakt01,
        fakt02: remoteAdd.fakt02,
        fakt03: remoteAdd.fakt03,
        fakt04: remoteAdd.fakt04,
        fakt05: remoteAdd.fakt05,
        fakt07: remoteAdd.fakt07,
        fakt09: remoteAdd.fakt09,
        fakt10: remoteAdd.fakt10,

        //právní
        pakt01: remoteAdd.pakt01,
        pakt02: remoteAdd.pakt02,
        pakt03: remoteAdd.pakt03,
        pakt04: remoteAdd.pakt04,
        pakt05: remoteAdd.pakt05,
        pakt06: remoteAdd.pakt06,
        pakt07: remoteAdd.pakt07,
        pakt08: remoteAdd.pakt08,
        pakt10: remoteAdd.pakt10,
        pakt11: remoteAdd.pakt11,

        //vecnné břemeno
        cakt01: remoteAdd.cakt01,
        cakt02: remoteAdd.cakt02,
        cakt03: remoteAdd.cakt03,
        cakt04: remoteAdd.cakt04,
        cakt05: remoteAdd.cakt05,
        cakt06: remoteAdd.cakt06,
        cakt07: remoteAdd.cakt07,
        cakt08: remoteAdd.cakt08,

        //dalsi
        el: remoteAdd.el,
        cena: remoteAdd.cena,
        cenatext: remoteAdd.cenatext,
        mena: remoteAdd.mena,
        energ: remoteAdd.e1

      },
      3: {},
      4: {},
    },
    images: [
      { base: null, modified: false, path: remoteAdd.obrazek1 },
      { base: null, modified: false, path: remoteAdd.obrazek2 },
      { base: null, modified: false, path: remoteAdd.obrazek3 },
      { base: null, modified: false, path: remoteAdd.obrazek4 },
      { base: null, modified: false, path: remoteAdd.obrazek5 },
      { base: null, modified: false, path: remoteAdd.obrazek6 },
      { base: null, modified: false, path: remoteAdd.obrazek7 },
      { base: null, modified: false, path: remoteAdd.obrazek8 },
      { base: null, modified: false, path: remoteAdd.obrazek9 },
      { base: null, modified: false, path: remoteAdd.obrazek10 },
    ],
    address: {
      street: null,
      cp: null,
      co: null,
      ce: null,
      ob: null,
      cbo: null,
      psc: null,
      distName: null,
      okres: remoteAdd.okres,
      place: remoteAdd.zadal,
      id_mesto: remoteAdd.id_mesto,
      id_katastr: remoteAdd.id_katastr
    },
  };
  if(
    remoteAdd.cakt01 =='0' &&
    remoteAdd.cakt02 =='0' &&
    remoteAdd.cakt03 =='0' &&
    remoteAdd.cakt04 =='0' &&
    remoteAdd.cakt05 =='0' &&
    remoteAdd.cakt06 =='0' &&
    remoteAdd.cakt03 =='0' &&
    remoteAdd.cakt08 =='0'
  ){
    zakazka.steps[2].bremeno = '2';
  } else{
    zakazka.steps[2].bremeno = '1';
  }
  if(
    remoteAdd.pakt01 =='0' &&
    remoteAdd.pakt02 =='0' &&
    remoteAdd.pakt03 =='0' &&
    remoteAdd.pakt04 =='0' &&
    remoteAdd.pakt05 =='0' &&
    remoteAdd.pakt06 =='0' &&
    remoteAdd.pakt07 =='0' &&
    remoteAdd.pakt08 =='0' &&
    remoteAdd.pakt10 =='0' &&
    remoteAdd.pakt11 ==''
  ){
    zakazka.steps[2].pravniVady = '2';
  } else{
    zakazka.steps[2].pravniVady = '1';
  }
  if(
    remoteAdd.fakt01 =='0' &&
    remoteAdd.fakt02 =='0' &&
    remoteAdd.fakt03 =='0' &&
    remoteAdd.fakt04 =='0' &&
    remoteAdd.fakt05 =='0' &&
    remoteAdd.fakt07 =='0' &&
    remoteAdd.fakt09 =='0' &&
    remoteAdd.fakt10 ==''
  ){
    zakazka.steps[2].vady = '2';
  } else{
    zakazka.steps[2].vady = '1';
  }
  const obec = this.getObecById(remoteAdd.id_mesto);
  const okres = this.getOkresById(remoteAdd.okres);

  
  const token = store.getters.tokenIsValid ? store.getters.getToken : await store.dispatch('getToken');
  // console.log(token);
  for( const pos in zakazka.images ){
    let img = zakazka.images[pos];
    // console.log(img)
    if( typeof img.path != 'undefined'){
      if(img.path.includes('/test/')){
      img.path = img.path.replace('/test/','/foto/');///pridano, nejspis chyba jenom v testovacim modu
      }
      await axios.axios.post('obrazek/', {
        token: token,
        url: img.path
      }).then(response => {
        // console.log(response)
        if( response.data.status == 'OK' ){
          
          let base = 'data:image/jpeg;base64,' + response.data.info.binary;
          zakazka.images[pos].base = base;
        }
      });
    }
  };

  if( okres ){
    zakazka.address.distName = okres.okres;
  }

  if( obec ){
    zakazka.address.ob = obec.nazev;
    this.getAddressValues(remoteAdd.zadal, obec.nazev, (results)=> {
      if(!results.length ){
        return;
      }
      zakazka.address.latitude = results[0].coords.y;
      zakazka.address.longitude = results[0].coords.x;
      let label = results[0].label;
      label = label.substr(0, label.indexOf(',') );
      let matches = label.match('^(.*[^0-9]+) (([1-9][0-9]*)\\/)?([1-9][0-9]*[a-cA-C]?)$');
      if (!matches){
        return;
      }
      zakazka.address.street = matches[1];
      zakazka.address.cp = matches[3];
      zakazka.address.co = matches[4];
    });
  }

  if (remoteAdd.zadanytyp == 6) {
    zakazka.steps[1].pocet_pokoju = remoteAdd.pocet_pokoju;
    zakazka.steps[2].uzit_plocha = remoteAdd.uzit_plocha;
    zakazka.steps[2].plochapozem = remoteAdd.plochapozem;
    zakazka.steps[2].stav = remoteAdd.stav;
    zakazka.steps[2].postavenoz = remoteAdd.postavenoz;
    zakazka.steps[2].poloha = remoteAdd.poloha;
    zakazka.steps[2].vytapeni = remoteAdd.vytapeni;

    zakazka.steps[2].plocha_sklep = remoteAdd.plocha_sklep;
    zakazka.steps[2].plocha_lodzie = remoteAdd.plocha_lodzie;
    zakazka.steps[2].pocet_garaz = remoteAdd.pocet_garaz;
    zakazka.steps[2].bazen = remoteAdd.bazen;
    zakazka.steps[2].plocha_balkon = remoteAdd.plocha_balkon;
    zakazka.steps[2].plocha_terasa = remoteAdd.plocha_terasa;
    zakazka.steps[2].park_pocet = remoteAdd.park_pocet;
  }

  if (remoteAdd.zadanytyp == 4) {
    zakazka.steps[2].cislo_jednotky = remoteAdd.cislo_jednotky;
    zakazka.steps[2].pater = remoteAdd.pater;
    zakazka.steps[2].patro = remoteAdd.patro;
    zakazka.steps[2].uzit_plocha = remoteAdd.uzit_plocha;
    zakazka.steps[2].stav =  remoteAdd.stav;
    zakazka.steps[2].postavenoz = remoteAdd.postavenoz;
    zakazka.steps[2].vytah = remoteAdd.vytah;
    zakazka.steps[2].vytapeni = remoteAdd.vytapeni;
    zakazka.steps[2].plocha_sklep = remoteAdd.plocha_sklep;
    zakazka.steps[2].plocha_lodzie = remoteAdd.plocha_lodzie;
    zakazka.steps[2].pocet_garaz = remoteAdd.pocet_garaz;
    zakazka.steps[2].plocha_balkon = remoteAdd.plocha_balkon;
    zakazka.steps[2].plocha_terasa = remoteAdd.plocha_terasa;
    zakazka.steps[2].park_pocet = remoteAdd.park_pocet;
  }

  if( remoteAdd.zadanytyp == 3 ){
    zakazka.steps[2].id_parcela = remoteAdd.id_parcela;
    zakazka.steps[2].celkova_plocha = remoteAdd.celkova_plocha;
    zakazka.steps[2].inzsite = remoteAdd.inzsite;
    zakazka.steps[2].odpad = remoteAdd.odpad;
  }

  if (remoteAdd.zadanytyp == 2) {
    zakazka.steps[2].uzit_plocha = remoteAdd.uzit_plocha;
    zakazka.steps[2].plochapozem = remoteAdd.plochapozem;
    zakazka.steps[2].stav = remoteAdd.stav;
    zakazka.steps[2].postavenoz = remoteAdd.postavenoz;
    zakazka.steps[2].poloha = remoteAdd.poloha;
    zakazka.steps[2].vytapeni = remoteAdd.vytapeni;
    zakazka.steps[2].plocha_terasa = remoteAdd.plocha_terasa;
    zakazka.steps[2].pocet_garaz = remoteAdd.pocet_garaz;
    zakazka.steps[2].park_pocet = remoteAdd.park_pocet
  }

  if (remoteAdd.zadanytyp == 11) {
    zakazka.steps[2].uzit_plocha = remoteAdd.uzit_plocha;
    zakazka.steps[2].plochapozem = remoteAdd.plochapozem;
    zakazka.steps[2].stav = remoteAdd.stav;
    zakazka.steps[2].postavenoz = remoteAdd.postavenoz;
  }

  if( remoteAdd.aktivovatcelkem == 1){
    zakazka.activated = true;
  }else{
    zakazka.activated = false;
  }
  // console.log(zakazka);

  return zakazka;
}

const getOkresById = function(okres_id){
  return okres.okres.find( e => e.id == okres_id );
}

const getKatastrById = function(katastr_id){
  return katastr.katastr.find( e => e.id == katastr_id );
}

const getObecById = function(obec_id){
  return obce.obce.find( e => e.id == obec_id );
}

const getAddressValues = async function(zadal, obec, resultsCallback ){
  const query = zadal + ', ' + obec;
  new SMap.Geocoder(query, (geocoder) => {
    const vysledky = geocoder.getResults()[0].results;
    resultsCallback(vysledky);
    });  
};

export default {
  vuexToApi,
  apiToVuex,
  getOkresById,
  getKatastrById,
  getObecById,
  getAddressValues
}