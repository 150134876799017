<template>
  <section class="top-bar">
    <div class="container">
      <div class="logo">
        <picture>
          <img src="../assets/images/logo.svg" alt="logo" data-uk-svg />
        </picture>
      </div>
      <a class="uk-navbar-toggle" uk-navbar-toggle-icon uk-toggle="target: #offcanvas-nav"></a>
    </div>
    <div id="offcanvas-nav" data-uk-offcanvas="mode: none; overlay: true; flip: true">
      <div class="uk-offcanvas-bar">
        <button class="uk-offcanvas-close" type="button" uk-close></button>
        <div class="logo">
          <picture>
            <img src="../assets/images/logo.svg" alt="logo" />
          </picture>
        </div>
        <div class="content">
          <h3>Máte dotaz?</h3>
          <p>Rádi Vás uslyšíme (8 - 16 h)</p>
          <p>
            <a class="phone" href="tel:+420 770 183 128"><img src="../assets/images/ico-tel.png" />+420 770 183 128</a>
          </p>
          <button class="offcanvas-logout uk-offcanvas-close" @click="logout">Odhlásit se</button>
          <button class="offcanvas-logout uk-offcanvas-close" @click="updateAdverts">Aktualizovat inzeráty</button>
          <button class="offcanvas-logout uk-offcanvas-close" @click="openVideoPanel">Video návod</button>
          <p v-if="pushEnabled == 0">Notifikace nejsou podporovány nebo jsou zakázány</p>
          <button v-else-if="pushEnabled == 1" class="offcanvas-logout uk-offcanvas-close">Notifikace povoleny</button>
          <button v-else class="offcanvas-logout uk-offcanvas-close" @click="enableNotification">
            Povolit notifikace
          </button>
          <p>
            <a class="" href="https://www.realitni-samoobsluha.cz/obchodni-podminky/" target="_blank"
              >Obchodní podmínky</a
            >
          </p>
          <button class="offcanvas-logout uk-offcanvas-close" @click="getDeleteHash">Zrušit účet</button>
        </div>
        <!--<ul uk-accordion>
          <li>
            <a class="uk-accordion-title" href="#">
              <span class="icon">
                <img src="../assets/images/bell.png">
                <span class="number">0</span>
              </span>
              <span>Upozornění</span>
            </a>
            <div class="uk-accordion-content">
              <p><strong>Zpráva:</strong>lorem ipsum</p>
              <p><strong>Zpráva:</strong>lorem ipsum</p>
              <p><strong>Zpráva:</strong>lorem ipsum</p>
            </div>
          </li>
        </ul>-->
      </div>
    </div>
  </section>
</template>
<script>
import { pushSubscribe } from "@/push-notification";
import { isPushSupported } from "@/push-notification";
import { isNotificationsAllowed } from "@/push-notification";
import { isBlockedByUser } from "@/push-notification";
import eventBus from "../../libs/event-bus";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "top-nav",
  emits: ["openVideo", "hashModal", "updateAdverts"],
  props: ["isHash"],
  mounted() {
    eventBus.$on("subscriptionRecalculate", (payload) => {
      this.refreshKey = !this.refreshKey;
    });
    this.evaluateNotification();
  },
  data() {
    return {
      pushEnabled: 1,
      refreshKey: false,
      hash: "",
    };
  },
  computed: {
    ...mapGetters(["getId", "tokenIsValid", "getToken"]),
  },
  watch: {
    isHash(newVal) {
      if (newVal === "") {
        this.hash = "";
      }
    },
  },
  methods: {
    async getDeleteHash() {
      // funkce získání odkazu pro zrušení účtu
      const id = this.getId;
      let token = this.tokenIsValid ? this.getToken : await this.$store.dispatch("getToken");
      const response = await axios.post("zrusit/", { token: token, id: id });
      if (response.data.status == "OK") {
        this.hash = response.data.info.odkaz;
        this.$emit("hashModal", {
          status: "ok",
          hash: this.hash,
        });
      } else {
        this.$emit("hashModal", {
          status: "error",
          hash: "",
        });
      }
    },
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push("/login");
    },
    async reset() {
      this.$store.dispatch("reset");
    },
    openVideoPanel: function (event) {
      if (event) {
        this.$emit("openVideo", event);
      }
    },
    updateAdverts() {
      this.$emit("updateAdverts");
    },
    async enableNotification() {
      const subscribe = await pushSubscribe();
      setTimeout(() => {
        console.log("Zaregistrováno k notifikacím: (true/false)", subscribe);
        this.evaluateNotification();
        console.log(this.pushEnabled);
      }, 1000);
    },
    evaluateNotification() {
      if (isNotificationsAllowed()) {
        return (this.pushEnabled = 1);
      }
      if (isPushSupported() && !isBlockedByUser()) {
        return (this.pushEnabled = 2);
      } else {
        return (this.pushEnabled = 0);
      }
    },
  },
};
</script>
<style lang="less">
section.top-bar {
  .container {
    position: relative;
    max-width: 42.8rem;
    margin: auto;
    background: #f2f2f2;
    .logo {
      position: relative;
      padding: 0.5rem 0;
      picture {
        img,
        svg {
          max-height: 4.3rem;
          max-width: 14rem;
          display: block;
          margin-left: 2rem;
        }
      }
    }
    .uk-navbar-toggle {
      position: absolute;
      top: 0;
      right: 2rem;
      bottom: 0;
      margin: auto;
      color: #2fac66;
      svg {
        width: 2.7rem;
      }
    }
  }
  .uk-offcanvas {
    .uk-offcanvas-bar {
      background: #fff;
      padding: 2.9rem 3rem;
      overflow-x: hidden;
      .uk-offcanvas-close {
        color: #2fac66;
        svg {
          line {
            stroke-width: 1.2;
          }
        }
      }
      .logo {
        margin-bottom: 4rem;
        picture {
          img {
            max-height: 4.3rem;
            max-width: 14rem;
          }
        }
      }
      .uk-accordion {
        position: relative;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid #dedede;
        margin-top: 0;
        li {
          .uk-accordion-title {
            font-family: "Poppins";
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.7rem;
            color: #7c7c7b;
            margin-top: 0rem;
            margin-bottom: 0rem;
            .icon {
              position: relative;
              margin-right: 1.5rem;
              img {
                display: inline-block;
              }
              .number {
                position: absolute;
                top: 0;
                right: -0.5rem;
                width: 1.2rem;
                height: 1.2rem;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                background: #fff;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                font-family: "Poppins";
                font-weight: 400;
                font-size: 1rem;
                line-height: 1rem;
                color: #03a3bd;
              }
            }
            &:before {
              background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E);
            }
          }
          .uk-accordion-content {
            p {
              font-family: "Poppins";
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 2.1rem;
              color: #7c7c7b;
              margin-top: 1rem;
              margin-bottom: 1rem;
              strong {
                font-weight: 700;
              }
            }
          }
        }
      }
      .content {
        h3 {
          position: relative;
          font-family: "Poppins";
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 2.7rem;
          color: #7c7c7b;
          margin-top: 1rem;
          margin-bottom: 0.5rem;
          z-index: 2;
        }
        p {
          position: relative;
          font-family: "Poppins";
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 2.1rem;
          color: #7c7c7b;
          margin-top: 1rem;
          margin-bottom: 1rem;
          z-index: 2;
          a.phone {
            font-family: "Poppins";
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.7rem;
            color: #03a3bd;
            img {
              display: inline-block;
              margin-right: 1rem;
              max-height: 1.9rem;
              transform: rotateZ(90deg);
            }
          }
          a {
            position: relative;
            top: 0;
            left: 0;
            font-family: "Poppins";
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.1rem;
            color: #04ac66;
            margin-top: 0rem;
            margin-bottom: 1rem;
            border: 0;
            background: transparent;
            padding: 0;
            cursor: pointer;
            display: flex;
            width: 100%;
            padding-bottom: 1rem;
            border-bottom: 1px solid #dedede;
            text-align: left;
          }
        }
        .offcanvas-logout {
          position: relative;
          top: 0;
          left: 0;
          font-family: "Poppins";
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 2.1rem;
          color: #04ac66;
          margin-top: 0rem;
          margin-bottom: 1rem;
          border: 0;
          background: transparent;
          padding: 0;
          cursor: pointer;
          display: flex;
          width: 100%;
          padding-bottom: 1rem;
          border-bottom: 1px solid #dedede;
          text-align: left;
        }
        &:before {
          content: "";
          position: absolute;
          display: block;
          top: 20rem;
          right: -21rem;
          width: 52.6rem;
          height: 43.6rem;
          background: url("../assets/images/offcanvas-bg.png");
          background-size: cover;
          background-repeat: no-repeat;
          z-index: 1;
        }
      }
    }
  }
}
</style>
