import {createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from "../store/index.js";

const routes =  [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta:{
          needsAuth:true
        }
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/LoginView.vue'),
  },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta:{
          needsAuth:true
        }
    },
    {
        path: '/adverts',
        name: 'Adverts',
        component: () => import('../views/Adverts.vue'),
        meta:{
          needsAuth:true
        }
    },
    {
      path: '/takers',
      name: 'Takers',
      component: () => import('../views/Takers.vue'),
      meta:{
        needsAuth:true
      }
  },
    // {
    //     path: '/register',
    //     name: 'Register',
    //     component: () => import( '../components/Register.vue')
    // },
    {
      path: '/firststep/:id',
      name: 'firstStep',
      component: () => import( '../views/steps/FirstStep.vue'),
      meta:{
        needsAuth:true
      }
    },
    {
      path: '/secondstep/:id',
      name: 'secondStep',
      component: () => import( '../views/steps/SecondStep.vue'),
      meta:{
        needsAuth:true
      }
    },
    {
      path: '/thirdstep/:id',
      name: 'thirdStep',
      component: () => import( '../views/steps/ThirdStep.vue'),
      meta:{
        needsAuth:true
      }
    },
    {
      path: '/fourthstep/:id',
      name: 'fourthStep',
      component: () => import( '../views/steps/FourthStep.vue'),
      meta:{
        needsAuth:true
      }
    },
    {
      path: '/done/:id',
      name: 'done',
      component: () => import( '../views/steps/done.vue'),
      meta:{
        needsAuth:true
      }
    },
    {
      path: '/viewonly/:id',
      name: 'viewonly',
      component: () => import( '../views/viewonly.vue'),
      meta:{
        needsAuth:true
      }
    },
  {
    path: '/changeadvert/:id/:requestType',
    name: 'changeadvert',
    component: () => import( '../views/changeAdvert.vue'),
    meta:{
      needsAuth:true
    }
  },
  {path: "/:notFound(.*)",redirect:"/"}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

/*router.beforeEach( (to, from, next) => {
   if( to.path === '/4' && store.state.hasFourthStep ){
       next(false)
   }else{
       next();
   }
});*/
router.beforeEach(function(to, from, next){
  if(to.meta.needsAuth && store.getters.isAuthenticated){
    next()
  } else if(to.meta.needsAuth && !store.getters.isAuthenticated){
    next({
      name:'Login'
    })
  } else if(!to.meta.needsAuth){
    next()
  }
})
export default router
